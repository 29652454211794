import React from 'react'
import { NavLink,useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CContainer,
	CHeader,
	CHeaderDivider,
	CImage,
	CForm,
	CRow,
	CCol,
	CButton,
	CFormInput,
	CInputGroup,
	CInputGroupText,
	CLink,
	CTooltip,
	CFormSelect,
	CDropdown,CDropdownToggle,CDropdownMenu,CDropdownItem,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { Konfirm } from '../../helpers/onConfirm'
import { UFunc,cBulanPanjang,cBulanPendek } from '../../helpers/functions'

const pjson 	= require('../../../package.json')
const Headersubantrianexpired = (props) => {
	const { showToast } = props 

	//--DOM--/
	const uNavigate 			= useNavigate()
	const uDispatch 			= useDispatch()
	//--END DOM--/

	const uHeaderActionObj  	= useSelector(state => state.gInitHeaderAction)
	const uBahasaObj  			= useSelector(state => state.listBahasa)
	const uActiveroute			= useSelector(state => state.activeRoute)
	const uSystemsetting		= useSelector(state => state.gSystemsetting)
	const uKeywordObj			= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uTokenObj				= JSON.parse(localStorage.getItem("token")||"{}");
	const uEfekapp				= useSelector(state => state.effectApp);
	const uDatacabangArr		= JSON.parse(localStorage.getItem("cabanglist")||"[]")
	const uCabangfilterArr		= uTokenObj.userhak==="FREEROLE" ? uDatacabangArr
		: uDatacabangArr.filter(vItems=>(uTokenObj.user_cabangarr || []).includes(vItems.id.toString()))
	
	const uTglnowDT 			= new Date();
	const uTglsatuDT 			= new Date(uTglnowDT.getFullYear(), uTglnowDT.getMonth(), 1);
	const uTglSATUDdefault		= UFunc.DbDate(uTglsatuDT);
	const uTahundefault			= (UFunc.toDate()).getFullYear();
	const vFilterview			= uKeywordObj.filter_antrianexpired || {};
	const [uInpCabangid,setInpCabangid]	= React.useState(vFilterview.cabang_id||uTokenObj.user_cabangid);
	const [uInpTglfilter,setInpTglfilter]	= React.useState(uTglSATUDdefault);

	const hdlChangefilter = () => {
		if(parseInt(uInpCabangid) <= 0) {
			showToast("[] UNDEFINED.."); return
		}
		if((uInpTglfilter||"") === "") 
			{ showToast("[] UNDEFINED.."); return }

		//---SAVE_KEYWORD--/
		uKeywordObj.filter_antrianexpired = {
			cabang_id: uInpCabangid,
			tgl_filter: uInpTglfilter,
		}
    	localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
		//---END SAVE_KEYWORD--/

		uHeaderActionObj.isHeaderView = true;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
		uDispatch({type: "set", effectApp: !uEfekapp});
	}
	const hdlKlikReset = async() => {
		const vProses = () => {
			//---CLEAR_KEYWORD--/
			uKeywordObj.filter_antrianexpired = undefined; 
	    	localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
	    	setInpTglfilter(uTglSATUDdefault);
	    	setInpCabangid(uTokenObj.user_cabangid);
			//---END CLEAR_KEYWORD--/
			
			//setHeaderView(true);

			uHeaderActionObj.isHeaderView = true;
			uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
			uDispatch({type: "set", effectApp: !uEfekapp});
		}

		if(uInpTglfilter !== uTglSATUDdefault
			|| uInpCabangid !== uTokenObj.user_cabangid
		) {
			if(await Konfirm(uBahasaObj.confirm_reset||"Apakah Yakin Akan Direset ?")) vProses();
		} else vProses();	
	}
	React.useEffect(()=>{
		//if(uISFirstload) return;

		hdlChangefilter();
	},[uInpTglfilter,uInpCabangid])

	//console.log("Headersubantrianexpired - uActiveroute => "+(uActiveroute));
	return (
		<>
		<div className="d-none d-lg-block">
		<CImage src={pjson.homepage+"api/images/menus_"+(uActiveroute||"").toLowerCase()+".png"} 
			className="me-2"
			height={30}/>
		{uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]||""}
		</div>

		<CForm className="d-flex justify-content-center"
			style={{
				minWidth:((uTokenObj.userhak==="FREEROLE" 
						|| uTokenObj.userhak==="ADMIN")
					&& uDatacabangArr.length > 1
					) ? 300 : 200,
				width:"auto"
			}}
			id="idformfilter">
			{(uDatacabangArr.length > 1 && 
				(uTokenObj.userhak==="FREEROLE" || uTokenObj.userhak==="ADMIN")
			) && (
			<>
				<CFormSelect size="sm"
					value={uInpCabangid} 
					onChange={(e) => setInpCabangid(e.target.value)}
					className="me-1 d-none d-md-inline"
					id="inpcabangid">
					{uCabangfilterArr.map((vItems,vKeys)=>{
						return (<option value={vItems.id} key={vKeys}>{vItems.cabang_nama.toUpperCase()}</option>)
					})}
				</CFormSelect>

				<CDropdown className="d-md-none me-1">
					<CDropdownToggle caret={true} className="px-1 border fw-bolder" color="light">
						{(uDatacabangArr.filter(vItems => 
							parseInt(vItems.id) === parseInt(uInpCabangid)
							)[0].cabang_kode || "").toUpperCase()
						}
					</CDropdownToggle>
					<CDropdownMenu>
						{uCabangfilterArr.map((vItems,vKeys)=>{
							return (
							<CDropdownItem key={vKeys} onClick={()=>setInpCabangid(vItems.id)} className="classcursorpointer">
								{vItems.cabang_kode.toUpperCase()}
								<span className="mx-1">-</span>
								{vItems.cabang_nama.toUpperCase()}
							</CDropdownItem>
							)
						})}
					</CDropdownMenu>
				</CDropdown>
			</>
			)}

			<CInputGroup style={{minWidth:200,maxWidth:225,width:"auto"}}
				size="sm">
			<CFormSelect size="sm"
				value={uInpTglfilter}
				onChange={(e)=>setInpTglfilter(e.target.value)}
				id="inpbulanid">
				{[...new Array(6)].map((_,vKeys)=>{
					const vTglNow	= new Date();
					const vMonthidx	= vTglNow.getMonth() - vKeys < 0 
						? (vTglNow.getMonth() - vKeys) + 12 
						: vTglNow.getMonth() - vKeys;
					const vYear		= vTglNow.getMonth() - vKeys < 0 
						? vTglNow.getFullYear()-1 : vTglNow.getFullYear();//-*/

					const vTglsatu	= new Date(vYear,vMonthidx,1);
					const vValue 	= UFunc.DbDate(vTglsatu);
					const vCaption 	= (cBulanPendek[vMonthidx]||"").toUpperCase()+" "+vYear; 

					return (
					<option value={vValue} key={vKeys}>{vCaption}</option>
					)
				})}
			</CFormSelect>
			</CInputGroup>
		</CForm>

		<div className="ms-1 d-none d-md-block">
			<CTooltip content={"--"+(uBahasaObj.caption_resetdata||"Reset View Tampilan")}>
			<CLink onClick={()=>hdlKlikReset()} className="classikon classikonreset classcursorpointer" />
			</CTooltip>
			
			<span className="me-1">&middot;</span>
			(<b id="idjmldata" className="text-primary">0</b> {uBahasaObj.word_data||"Data"})
		</div>
		</>
	)//>
}

export default Headersubantrianexpired
